import { CoreoUser } from '../types';
import { CoreoAPI } from './api.service';

import { login, LoginInput, LoginResponse, MFARecoverInput, MFARecoverResponse, MFAVerifyInput, MFAVerifyResponse, recover, register, verify } from './auth.queries';

const base64ToBase64url = b64 => {
  return b64.replace('_', '/').replace('-', '+');
}

export const decodeAuthToken = (token: string): CoreoUser => JSON.parse(atob(base64ToBase64url(token.split('.')[1])));

export const localLogin = async (email: string, password: string): Promise<{ token: string, pre2FAToken: string | null }> => {
  const result = await CoreoAPI.instance.graphql<LoginResponse, LoginInput>(login, { input: { email, password } });
  // FOR TESTING
  // return { token: TEST_TOKEN, pre2FAToken: '123' };
  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  const { token, pre2FAToken } = result.data.login;

  return { token, pre2FAToken };
}

export const verify2FAToken = async (pre2FAToken: string, token: string): Promise<string> => {
  const result = await CoreoAPI.instance.graphql<MFAVerifyResponse, MFAVerifyInput>(verify, { input: { pre2FAToken, token } });
  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  const token2Fa = result.data.mfaAuthenticatorVerify.token || null;

  if (!token2Fa) {
    throw new Error('No token available');
  }

  return token2Fa;
}

export const recover2FACode = async (pre2FAToken: string, recoveryCode: string): Promise<string> => {
  const result = await CoreoAPI.instance.graphql<MFARecoverResponse, MFARecoverInput>(recover, { input: { pre2FAToken, recoveryCode } });

  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  const token2Fa = result.data.mfaAuthenticatorRecover.token || null;

  if (!token2Fa) {
    throw new Error('No token available');
  }

  return token2Fa;
}

export const localSignup = async (email: string, password: string, displayName: string, marketingConsent: boolean, projectId: number): Promise<unknown> => {
  const result = await CoreoAPI.instance.graphql(register, {
    email,
    password,
    displayName,
    marketingConsent,
    projectId
  });

  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  return result.data;
}
