import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Capacitor } from '@capacitor/core';
import { CoreoAPI } from '../../services/api.service';

import { decodeAuthToken, localLogin, localSignup, recover2FACode, verify2FAToken } from '../../services/auth.service';
import SQLDatabase from '../../services/db/sql.service';
import { CoreoUser } from '../../types';
import { TypeKeys } from '../actions';
import { getAuthUser } from '../selectors';


let signupPollHandle: number;

export const authStart = () => ({
  type: TypeKeys.AUTH_START
});

export const authRestart = () => ({
  type: TypeKeys.AUTH_RESTART
});

export const authSuccess = (token: string, user: CoreoUser) => ({
  type: TypeKeys.AUTH_SUCCESS,
  token,
  user
});

export const authFail = (error: string) => ({
  type: TypeKeys.AUTH_FAIL,
  error
});

export const authVerifyTokenStart = () => ({
  type: TypeKeys.AUTH_VERIFY_START
});

export const authVerifyTokenSuccess = (token: string, user: CoreoUser) => ({
  type: TypeKeys.AUTH_VERIFY_SUCCESS,
  token,
  user
});

export const authVerifyTokenFail = (error: string) => ({
  type: TypeKeys.AUTH_VERIFY_FAIL,
  error
});

export const auth = (email: string, password: string) => async dispatch => {
  dispatch(authStart());

  try {
    const { token, pre2FAToken } = await localLogin(email, password);
    if (!token && !pre2FAToken) throw new Error('No token available');

    if (pre2FAToken) return pre2FAToken;

    const user: CoreoUser = decodeAuthToken(token);
    const userId = user.userId.toString();

    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.setUserId({
        userId
      });
      FirebaseCrashlytics.setUserId({
        userId
      });
    }
    CoreoAPI.instance.setAuthToken(token);
    dispatch(authSuccess(token, user));
  } catch (e) {
    dispatch(authFail(e.message));
    throw e;
  }
}

export const verify2FA = (pre2FAToken: string, code: string) => async dispatch => {
  dispatch(authVerifyTokenStart());

  try {
    const token = await verify2FAToken(pre2FAToken, code);

    const user: CoreoUser = decodeAuthToken(token);
    const userId = user.userId.toString();

    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.setUserId({
        userId
      });
      FirebaseCrashlytics.setUserId({
        userId
      });
    }
    CoreoAPI.instance.setAuthToken(token);
    dispatch(authVerifyTokenSuccess(token, user));
  } catch (e) {
    dispatch(authVerifyTokenFail(e.message));
    throw e;
  }
}

export const recover2FA = (pre2FAToken: string, code: string) => async dispatch => {
  dispatch(authVerifyTokenStart());

  try {
    const token = await recover2FACode(pre2FAToken, code);

    const user: CoreoUser = decodeAuthToken(token);
    const userId = user.userId.toString();

    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.setUserId({
        userId
      });
      FirebaseCrashlytics.setUserId({
        userId
      });
    }
    CoreoAPI.instance.setAuthToken(token);
    dispatch(authVerifyTokenSuccess(token, user));
  } catch (e) {
    dispatch(authVerifyTokenFail(e.message));
    throw e;
  }
}

export const signup = (email: string, password: string, displayName: string, marketing: boolean, projectId: number) => async dispatch => {
  dispatch(authStart());

  try {
    await localSignup(email, password, displayName, marketing, projectId);

    signupPollHandle = window.setInterval(async () => {
      try {
        await auth(email, password)(dispatch);
        window.clearInterval(signupPollHandle);
        signupPollHandle = null;
      } catch (e) {
        console.log('Not ready yet:', e);
      }
    }, 3000);
  } catch (e) {
    dispatch(authFail(e.message));
    throw e;
  }
}

export const signupPollCancel = () => {
  if (signupPollHandle) {
    window.clearInterval(signupPollHandle);
  }
}

export const authLogout = () => async dispatch => {


  if (Capacitor.isNativePlatform()) {
    FirebaseAnalytics.setUserId({
      userId: null
    });
    FirebaseCrashlytics.setUserId({
      userId: null
    });
  }

  dispatch({
    type: TypeKeys.AUTH_LOGOUT
  });

  CoreoAPI.instance.setAuthToken(null);
};

export const authUpdateUser = (username: string, displayName: string, image?: Blob) => async (dispatch, getState) => {
  dispatch({
    type: TypeKeys.AUTH_UPDATE_USER
  })

  const mutation = `mutation{
    user: updateProfile( input:{
      username: "${username}",
      displayName: "${displayName}"
    }) {
      token,
      user{
        id,
        imageUrl,
        username,
        displayName
      }
    }
  }`
  const formData = new FormData();
  if (typeof image !== 'undefined') {
    formData.append('image', image);
  }
  formData.append('query', mutation);
  const response = await CoreoAPI.instance.post(`/graphql`, formData);
  if (!response.ok) {
    const error = await response.statusText;
    dispatch({
      type: TypeKeys.AUTH_UPDATE_USER_FAILURE,
      error
    })
    throw error;
  }
  const res = await response.json();
  const oldUser = getAuthUser(getState());
  const imageUrl = res.data.user.user.imageUrl;
  await SQLDatabase.instance.query(`UPDATE users SET username = ?, displayName = ?, imageUrl = ? WHERE id = ?`, [username, displayName, oldUser.userId, imageUrl]);

  dispatch({
    type: TypeKeys.AUTH_UPDATE_USER_SUCCESS,
    user: {
      ...oldUser,
      displayName,
      username,
      imageUrl
    }
  });
}

export const authSetSignupProjectId = (projectId: number) => ({
  type: TypeKeys.AUTH_SET_SIGNUP_PROJECT_ID,
  projectId
});
